import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";

import { ImageCrop } from "../../../../components/cropImage";
import { customToast } from "../../../../components/notify";
import { IMG_ACCEPT_TYPES } from "../../../records/constants";
import useAvatarInput from "../../hooks/useAvatarInput";
import CharacterAvatar from "./CharacterAvatar";
import styles from "./CharacterForm.module.scss";

interface IProps {
    handleSetAvatarFile: (avatarFile: File) => void;
    avatarFile: File | null;
}
const validTypes = Object.values(IMG_ACCEPT_TYPES);
const AvatarInput = ({ handleSetAvatarFile, avatarFile }: IProps) => {
    const [beforeAvatarUrl, setBeforeAvatarUrl] = useState<File | null>(null);
    const [openCrop, setOpenCrop] = useState(false);

    const [cropConfirm, setCropConfirm] = useState<boolean>(false);
    const { oldAvatarUrl, avatarUrl, handleSaveAvatar, handleChangeAvatar, setAvatarUrl } = useAvatarInput({
        handleSetAvatarFile,
        avatarFile,
        setBeforeAvatarUrl,
        beforeAvatarUrl,
        setOpenCrop,
    });

    const handleFileCheck = (file: File) => {
        if (!validTypes.includes(file.type)) {
            customToast.error(`Invalid File Types`);
            return false;
        }
        return true;
    };

    const handleChangeBtn = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const isFileValid = handleFileCheck(file);
        if (!isFileValid) return;
        setOpenCrop(true);
        handleSaveAvatar(e);
        e.target.value = ""; // 沒加這行會沒辦法觸發onChange事件，當我上傳相同圖片時會沒有進這裡
    };

    const handleConfirmCrop = (croppedFile: File) => {
        setOpenCrop(false);
        setCropConfirm(true);
        setBeforeAvatarUrl(croppedFile);
    };

    const handleCancelCrop = () => {
        setOpenCrop(false);
        setBeforeAvatarUrl(null);
        setCropConfirm(false);
    };
    const handleOriginalImage = () => {
        setCropConfirm(false);
        if (beforeAvatarUrl) {
            setAvatarUrl(URL.createObjectURL(beforeAvatarUrl));
            handleChangeAvatar();
        }
    };

    useEffect(() => {
        if (cropConfirm && beforeAvatarUrl) {
            setAvatarUrl(URL.createObjectURL(beforeAvatarUrl));
            handleChangeAvatar();
            setCropConfirm(false);
            setOpenCrop(false);
        }
    }, [handleChangeAvatar, cropConfirm, beforeAvatarUrl, setAvatarUrl]);

    return (
        <Box position={"relative"}>
            <>
                {oldAvatarUrl && <CharacterAvatar isCircle={true} avatarUrl={avatarUrl ? avatarUrl : oldAvatarUrl} />}
                {!oldAvatarUrl && <CharacterAvatar isCircle={true} avatarUrl={avatarUrl} />}
            </>
            <Button
                variant='contained'
                sx={{ borderRadius: "100%" }}
                color='info'
                component='label'
                className={styles.avatarBtn}
            >
                <input type='file' id='file' hidden onChange={handleChangeBtn} />
                <EditRoundedIcon />
            </Button>
            {openCrop && beforeAvatarUrl && (
                <ImageCrop
                    handleToggle={() => setOpenCrop(false)}
                    openCrop={openCrop}
                    handleCancel={handleCancelCrop}
                    handleConfirm={handleConfirmCrop}
                    setOpenCrop={() => setOpenCrop((prev) => !prev)}
                    currentImage={beforeAvatarUrl}
                    showPreview={false}
                    handleNoCrop={handleOriginalImage}
                />
            )}
        </Box>
    );
};

export default AvatarInput;
