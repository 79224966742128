import clsx from "clsx";

import defaultCharacterImg from "../../../../assets/images/default-character.png";
import AssetWithSkeleton from "../../../../components/loading/AssetWithSkeleton";
import styles from "./CharacterAvatar.module.scss";

interface IProps {
    avatarUrl: string | null;
    isCircle?: boolean;
    isForm?: boolean;
}
const CharacterAvatar = ({ avatarUrl, isCircle = false, isForm = false }: IProps) => {
    return (
        <div className={clsx(styles.avatarContainer, { [styles.inForm]: isForm })}>
            <AssetWithSkeleton
                src={avatarUrl ? avatarUrl : defaultCharacterImg}
                variant={isCircle ? "circular" : "rectangular"}
                alt={"character"}
                classes={{
                    loading: clsx(styles.avatarImg, { [styles.inForm]: isForm }),
                    img: clsx(styles.avatarImg, { [styles.inForm]: isForm }),
                }}
            />
        </div>
    );
};
export default CharacterAvatar;
