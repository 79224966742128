// import { MIME_TYPES } from "../../../constants";

export const VIDEO_SIZE_LIMIT = 500 * 1024 * 1024;
export const MAX_KEYWORD_AMOUNT = 10;

export const IMG_ACCEPT_TYPES = ["jpg", "png", "gif"];
export const VIDEO_ACCEPT_TYPES = ["mp4"];

export const MAX_CONTENT_LENGTH = 500;
export const RECORD_PAGE_LIMIT = 100;
