import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetCharacterQuery } from "../services/charactersApiSlice";

interface IProps {
    handleSetAvatarFile: (avatarFile: File) => void;
    avatarFile: File | null;
    setBeforeAvatarUrl: (avatarFile: File) => void;
    beforeAvatarUrl: File | null;
    setOpenCrop: (openCrop: boolean) => void;
}
const useAvatarInput = ({
    handleSetAvatarFile,
    avatarFile,
    setBeforeAvatarUrl,
    beforeAvatarUrl,
    setOpenCrop,
}: IProps) => {
    const { characterId } = useParams();
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

    //>  ============== API ==============
    const { data: cInfo } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });

    const oldAvatarUrl = cInfo?.data?.character?.avatar_file_name;

    const handleSaveAvatar = (e: ChangeEvent<HTMLInputElement>): void => {
        const { files } = e.target;
        if (!files || files.length === 0) {
            setOpenCrop(false);
            return;
        }

        const [selectedFile] = files;

        setBeforeAvatarUrl(selectedFile);
        setOpenCrop(true);
    };

    const handleChangeAvatar = () => {
        if (!beforeAvatarUrl) return;
        handleSetAvatarFile(beforeAvatarUrl);
    };

    useEffect(() => {
        let fileReader: FileReader | undefined;
        let isCancel: boolean;
        if (avatarFile) {
            fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                //const { result } = e.target;
                if (typeof e?.target?.result === "string" && !isCancel) {
                    setAvatarUrl(e.target.result);
                }
            };
            fileReader.readAsDataURL(avatarFile);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [avatarFile]);

    return {
        oldAvatarUrl,
        avatarUrl,
        handleChangeAvatar,
        handleSaveAvatar,
        setAvatarUrl,
    };
};

export default useAvatarInput;
