import { Delete } from "@mui/icons-material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { IconButton } from "@mui/material";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { IMG_ACCEPT_TYPES } from "../../features/records/constants";
import ImageRenderer from "../../features/sales/components/item/ImageRender";
import useToggle from "../../hooks/useToggle";
import { getMimeHelper } from "../../utils/helper";
import { ImageCrop } from "../cropImage";
import { customToast } from "../notify/NotifyContainer";
import styles from "./FormUploadImg.module.scss";

interface IProps {
    name: string;
    acceptTypes?: string[];
    maxLimit?: number; // default = 10 * 1024 * 1024 => 10 MB
    children?: ReactNode;
    aspect?: string;
    showFixPreview?: boolean;
    showFreePreview?: boolean;
    fixCropTitle?: string;
    freeCropTitle?: string;
    defaultImageUrl?: string;
}

const FormUploadImg = ({
    children,
    name,
    acceptTypes = IMG_ACCEPT_TYPES,
    maxLimit = 10 * 1024 * 1024,
    aspect,
    showFixPreview,
    showFreePreview,
    fixCropTitle,
    freeCropTitle,
    defaultImageUrl,
}: IProps) => {
    const fileExtensions = getMimeHelper.getFileExtensions(acceptTypes); //顯示在UI上的檔案類型
    const acceptableMimeTypes = getMimeHelper.getMimeTypes(acceptTypes); //邏輯比較中的string
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [openCrop, setOpenCrop] = useToggle(false);
    const [singleFile, setSingleFile] = useState<File[]>([]);
    const location = useLocation();
    const pathnameSplit = location?.pathname?.split("/");
    const isSale = pathnameSplit.includes("sale");
    const {
        control,
        formState: { isSubmitted },
        reset,
        resetField,
    } = useFormContext();
    const { field } = useController({ name, control });

    const onDragEnter = () => wrapperRef.current?.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current?.classList.remove("dragover");

    const imageRenderProps = {
        fixAspect: aspect,
        showFixPreview: showFixPreview,
        showFreePreview: showFreePreview,
        fixCropTitle: fixCropTitle,
        freeCropTitle: freeCropTitle,
    };

    const ExistedImgRender = () => (
        <ImageRenderer
            {...imageRenderProps}
            handleRemove={() => {
                alert("remove");
            }}
            defaultImageUrl={defaultImageUrl}
        />
    );

    const TempImgRender = () => (
        <>
            {singleFile.map((item, index) =>
                isSale ? (
                    <ImageRenderer
                        key={index}
                        {...imageRenderProps}
                        handleRemove={oneFileRemove}
                        currentImage={singleFile[0]}
                    />
                ) : (
                    <div key={index} className={styles.fileListWrapper}>
                        <div className={styles.fileList} onClick={() => oneFileRemove()}>
                            <div className={styles.fileRow}>
                                <div className={styles.info}>
                                    <div className={styles.fileName}>{item.name}</div>
                                </div>
                            </div>
                            <IconButton className={styles.actionIcon}>
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                )
            )}
        </>
    );

    const onFileChange = useCallback(
        (e: SyntheticEvent<EventTarget> | null) => {
            const target = e?.target as HTMLInputElement;
            if (!target?.files) {
                setSingleFile([]);
                return;
            }
            const newFile = Object.values(target.files).map((file: File) => file);
            const [file] = newFile;
            // * validate file types
            if (!acceptableMimeTypes.includes(file.type)) {
                customToast.error(`Invalid File Types`);
                return;
            }
            // * validate file limits
            if (file.size > maxLimit) {
                customToast.error("File size over limit.");
                return;
            }
            setOpenCrop();
            field.onChange(file);
            setSingleFile(newFile);
        },
        [acceptableMimeTypes, maxLimit, setOpenCrop, field]
    );
    const onCropConfirm = useCallback(
        (croppedFile: File) => {
            setOpenCrop();
            setSingleFile([croppedFile]);
            field.onChange(croppedFile);
        },
        [field, setOpenCrop, setSingleFile]
    );

    //  remove files
    const oneFileRemove = () => {
        onFileChange(null);
        setSingleFile([]);
        //! reset 自己的欄位就好
        resetField(name);
    };
    const hasFile = singleFile.length > 0 || defaultImageUrl;
    useEffect(() => {
        if (!isSubmitted) return;
        setSingleFile([]);
    }, [isSubmitted, reset]);
    return (
        <>
            {!hasFile ? (
                <>
                    <div className={styles.wrapper}>
                        <div
                            className={styles.uploadSection}
                            ref={wrapperRef}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDrop={onDragLeave}
                        >
                            <div className={styles.headingWrapper}>
                                <div>
                                    <CloudUploadOutlinedIcon color='primary' sx={{ fontSize: 48 }} />
                                </div>
                                <div className='inputLabel'>{t("COMMON.Supported_files")}</div>
                                <div className='paragraph'>{fileExtensions.join(", ")}</div>
                            </div>
                            <Controller
                                name={name}
                                control={control}
                                render={({ field: { name, onBlur, ref } }) => (
                                    <input
                                        type='file'
                                        name={name}
                                        onBlur={onBlur}
                                        ref={ref}
                                        onChange={onFileChange}
                                        accept={acceptableMimeTypes.join(", ")}
                                        onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                                        className={styles.input}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.uploadExplanation}>{children}</div>
                </>
            ) : defaultImageUrl ? (
                <ExistedImgRender />
            ) : (
                <TempImgRender />
            )}

            {openCrop && (
                <ImageCrop
                    handleToggle={setOpenCrop}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    handleCancel={oneFileRemove}
                    handleConfirm={onCropConfirm}
                    currentImageUrl={defaultImageUrl}
                    currentImage={singleFile[0]}
                    fixAspect={aspect}
                    showFixPreview={showFixPreview}
                    showFreePreview={showFreePreview}
                    fixCropTitle={fixCropTitle}
                    freeCropTitle={freeCropTitle}
                />
            )}
        </>
    );
};
export default FormUploadImg;
